exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-areas-contentful-area-slug-js": () => import("./../../../src/pages/areas/{ContentfulArea.slug}.js" /* webpackChunkName: "component---src-pages-areas-contentful-area-slug-js" */),
  "component---src-pages-areas-index-js": () => import("./../../../src/pages/areas/index.js" /* webpackChunkName: "component---src-pages-areas-index-js" */),
  "component---src-pages-event-info-js": () => import("./../../../src/pages/event-info.js" /* webpackChunkName: "component---src-pages-event-info-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-performers-contentful-performer-slug-js": () => import("./../../../src/pages/performers/{ContentfulPerformer.slug}.js" /* webpackChunkName: "component---src-pages-performers-contentful-performer-slug-js" */),
  "component---src-pages-performers-index-js": () => import("./../../../src/pages/performers/index.js" /* webpackChunkName: "component---src-pages-performers-index-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-the-lock-challenge-js": () => import("./../../../src/pages/the-lock-challenge.js" /* webpackChunkName: "component---src-pages-the-lock-challenge-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-pages-who-are-we-js": () => import("./../../../src/pages/who-are-we.js" /* webpackChunkName: "component---src-pages-who-are-we-js" */)
}

